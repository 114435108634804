import React from 'react'
import { createPortal } from 'react-dom'

const InfoPopup = ({ isVisible, hideModal, message }) => {
  return isVisible ? createPortal 
  (
    <div className="popup">
      <div className="popup-content">
        <h3 className="text-info">Info</h3>
        <hr/>
        <p>{message}</p>
        <button 
          onClick={hideModal}
          className="btn btn-info btn-block my-2"
        >
          Ok
        </button>
      </div>
    </div>,
    document.body
  )
  : null
}

export default InfoPopup