import React, { useEffect, useState, useContext } from 'react'
import SurveyContext from '../../context/survey/surveyContext'
import AlertContext from '../../context/alert/alertContext'
import Progress from '../layout/Progress'
import Spinner from '../layout/Spinner'

const Publish = props => {
  const surveyContext = useContext(SurveyContext)
  const alertContext = useContext(AlertContext)
  const { addSurvey, updateSurvey, success, error, loading } = surveyContext
  const { setAlert } = alertContext

  /* set survey from props*/
  useEffect(() => {
    setSurvey(props.location.state.survey)
  // eslint-disable-next-line
  }, [])

  useEffect(() => {
    // Display error if 'error' not null
    if(error) {
      setAlert(error, 'danger')
    }
    // If success === true, clear current, notify, and push to publish
    if(success) {
      props.history.push({ pathname: '/home/surveys/complete', state: { survey: survey }})
    }
  // eslint-disable-next-line
  }, [error, success])

  const [ survey, setSurvey ] = useState(null)

  /* update survey state */
  const onChange = e => {
    setSurvey({ ...survey, [e.target.name]: e.target.value })
  }

  /* update published boolean from toggle */
  const onToggle = e => {
    // remove start and end dates if toggled to unpublished
    if(survey.published) {
      setSurvey((prevSurvey) => {
        const newSurvey = { ...survey }
        delete newSurvey["start"]
        delete newSurvey["end"]
        newSurvey["published"] = false
        return newSurvey
      })
    }
    else {
      setSurvey(({ published, ...prevState }) => ({...prevState, published: !published }))
    }
  }

  /* Save survey via context, push to manage component */
  const onSubmit = e => {
    e.preventDefault()
    if(survey._id) {
      updateSurvey(survey)
    }
    else {
      addSurvey(survey)
    }
  }

  if(survey === null || loading)
    return <Spinner />

  return (
    <>
      <Progress colors={["bg-mysecondary", "bg-mysecondary", "bg-mysecondary", "bg-secondary", "bg-secondary" ]} />
      <div className="bg-light">
        <div className="bg-mysecondary text-white p-3">
          <div>
            <h1 className="narrow-font m-0"><i className="fas fa-share-square d-inline"></i> Publish Survey</h1>
            <small className="">Powered by &copy; Quizzle</small>
          </div>
        </div>

        <form onSubmit={onSubmit} className="my-form">
          <h1 className="narrow-font text-warning mb-3">{survey.title}</h1>

          <div className="custom-control custom-switch">
            <input 
              type="checkbox"
              className="custom-control-input"
              id="customSwitch1"
              name="published"
              value={survey.published}
              checked={survey.published}
              onChange={onToggle}
            />
            <label className="custom-control-label" htmlFor="customSwitch1">
              {survey.published ? 
                <span className="text-primary">Published</span> 
                : 
                <span className="text-secondary">Not Published</span>
              }
            </label>
          </div>
          <small className="text-muted">Toggle switch to publish or unpublish survey</small>

          <div className="form-group pt-3">
            <label className="text-mysecondary" htmlFor="start">Start Date</label>
            <input 
              type="date"
              name="start"
              className="form-control"
              value={survey.start ? survey.start.substring(0,10) : ''}
              onChange={onChange}
              disabled={!survey.published}
            />
            <small className="form-text text-muted">Leave blank to start immediately</small>
          </div>
          <div className="form-group">
            <label className="text-mysecondary" htmlFor="end">End Date</label>
            <input
              type="date"
              name="end"
              className="form-control"
              value={survey.end ? survey.end.substring(0,10) : ''}
              onChange={onChange}
              disabled={!survey.published}
            />
            <small className="form-text text-muted">Leave blank for survey to remain active indefinitely</small>
          </div>
          <div className="d-flex justify-content-end">
            <button type="button"
              className="btn btn-danger mr-3"
              onClick={() => props.history.push({ pathname: '/home/surveys/edit_survey', state: { survey: survey }})}
            >
              Back
            </button>
            <button type="submit" className="btn btn-dark">Finish</button>
          </div>
        </form>
      </div>
    </>
  )
}

export default Publish