import {
  GET_RESPONSES,
  UPDATE_RESPONSE,
  DELETE_RESPONSE,
  CLEAR_RESPONSES,
  RESPONSE_ERROR
} from '../types'

export default (state, action) => {
  switch (action.type) {
    case GET_RESPONSES:
      return {
        ...state,
        responses: action.payload
      }
    case UPDATE_RESPONSE:
      return {
        ...state
      }
    case DELETE_RESPONSE:
      return {
        ...state,
        responses: state.responses.filter(response => response._id !== action.payload)
      }
    case CLEAR_RESPONSES:
      return {
        ...state,
        responses: null
      }
    case RESPONSE_ERROR:
      return {
        ...state
      }
    default:
      return state
  }
}