import React, { useContext, useEffect } from 'react'
import SurveyContext from '../../context/survey/surveyContext'
import ResponseContext from '../../context/response/responseContext'
import DashboardCards from './DashboardCards'
import Status from './Status'
//import PaginatedStatus from './PaginatedStatus'
import Graphs from './Graphs'
import Spinner from '../layout/Spinner'

const Dashboard = () => {
  const surveyContext = useContext(SurveyContext)
  const responseContext = useContext(ResponseContext)
  const { surveys, getSurveys } = surveyContext
  const { responses, getResponses } = responseContext

  /* Load users surveys into survey context*/
  useEffect(() => {
    getSurveys()
    getResponses()
    // eslint-disable-next-line
  }, [])

  if(!surveys || !responses)
    return <Spinner/>

  return (
    <>
      <DashboardCards />
      <Status />
  {/*    <PaginatedStatus itemsPerPage={4} />    */}
      <Graphs />
    </>
  )
}

export default Dashboard