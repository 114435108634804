import React from 'react'

const About = () => {
  return (
    <div>
      <h1>About this App</h1>
      <p>This app does nothing</p>
    </div>
  )
}

export default About