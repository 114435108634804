import React, { useReducer } from 'react'
import axios from 'axios'
import AuthContext from './authContext'
import authReducer from './authReducer'
import setAuthToken from '../../utils/setAuthToken'

import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  CLEAR_ERRORS
} from '../types'

const AuthState = props => {
  const initialState = {
    token: localStorage.getItem('token'),
    isAuthenticated: null,
    loading: true,
    error: null,
    success: null,
    user: null
  }

  const [state, dispatch] = useReducer(authReducer, initialState)

  // Load User
  const loadUser = async () => {
    if(localStorage.token) {
      setAuthToken(localStorage.token)
    }
    try {
      const res = await axios.get('/api/auth')
      dispatch({ type: USER_LOADED, payload: res.data })
    } catch (err) { 
        dispatch({ type: AUTH_ERROR }) 
    }
  }

  // Register User
  const registerUser = async user => {
    const config = { headers: { 'Content-type' : 'application/json' }}
    try {
      const res = await axios.post('/api/auth/register', user, config)
      dispatch({ type: REGISTER_SUCCESS, payload: res.data })
      loadUser()
    } catch (err) {
        //console.log(err.response)
        dispatch({ type: REGISTER_FAIL, payload: err.response.data.msg })
    }
  }

  // Login User
  const loginUser = async user => {
    const config = { headers: { 'Content-type': 'application/json' }}
    try {
      const res = await axios.post('/api/auth', user, config)
      dispatch({ type: LOGIN_SUCCESS, payload: res.data })
      loadUser()
    } catch (err) {
        dispatch({ type: LOGIN_FAIL, payload: err.response.data.msg })
    }
  }

  // Update User
  const updateUser = async user => {
    const config = { headers: { 'Content-type' : 'application/json' }}
    try {
      const res = await axios.put('/api/auth', user, config)
      dispatch({ type: USER_UPDATE_SUCCESS, payload: res.data })
    } catch (err) {
        dispatch({ type: USER_UPDATE_FAIL, payload: err.response.data.msg })
    }
  }

  // Logout User
  const logout = () => {
    dispatch({ type: LOGOUT })
  }

  // Clear Errors
  const clearErrors = () => dispatch({ type: CLEAR_ERRORS })

  return (
    <AuthContext.Provider
      value={{
        token: state.token,
        isAuthenticated: state.isAuthenticated,
        loading: state.loading,
        error: state.error,
        success: state.success,
        user: state.user,
        loadUser,
        updateUser,
        registerUser,
        loginUser,
        clearErrors,
        logout
      }}>
      { props.children }  
    </AuthContext.Provider>
  )
}

export default AuthState