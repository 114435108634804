/* https://github.com/bradtraversy/devconnector_2.0/tree/master/client/src/utils */

import axios from 'axios'


const setAuthToken = token => {
  if(token) {
    axios.defaults.headers.common['x-auth-token'] = token
  //  api.defaults.headers.common['x-auth-token'] = token
  } else {
    delete axios.defaults.headers.common['x-auth-token']
  //  delete api.defaults.headers.common['x-auth-token']
  }
}

export default setAuthToken