import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import AuthContext from '../../context/auth/authContext'
import SurveyContext from '../../context/survey/surveyContext'

const Navbar = () => {
  const authContext = useContext(AuthContext)
  const surveyContext = useContext(SurveyContext)
  const { logout, isAuthenticated } = authContext
  const { clearSurveys } = surveyContext

  /* */
  const onLogout = () => {
    logout()
    clearSurveys()
  }

  return (
    <nav className="navbar navbar-expand-sm navbar-dark bg-myprimary py-3">
      <div className="container">
        <div className="d-flex mr-3">
          <i className="fas fa-question-circle fa-3x px-3 text-white"></i>
          <a className="navbar-brand" href="!#">QUIZZLE</a>
        </div>

        { /* isAuthenticated */ false &&
          <div className="input-group">
            <input className="form-control py-2 border-right-0 border" type="search" placeholder="search" id="search-input"/>
            <span className="input-group-append">
              <div className="input-group-text bg-white"><i className="fa fa-search"></i></div>
            </span>
          </div> 
        }

        { isAuthenticated &&
          <ul className="navbar-nav ml-3">
            <li className="nav-item">
              <Link to="/home/about" className="nav-link">About</Link>
            </li>
            <li className="nav-item">
              <Link to="/home/contact" className="nav-link">Contact</Link>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#!" id="userDropdown" data-toggle="dropdown" role="button">
                Account
              </a>
              <div className="dropdown-menu">
                <Link to="/home/profile" className="dropdown-item">Profile</Link>
                <Link to="/home/contact" className="dropdown-item">Support</Link>
                <div className="dropdown-divider"></div>
                <a onClick={onLogout} href="#!" className="dropdown-item">Logout</a>
              </div>
            </li>
          </ul> 
        }
      </div>
    </nav>
  )
}

export default Navbar