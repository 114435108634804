import React, { useState, useContext, useEffect } from 'react'
import Question from './Question'
import SurveyContext from '../../context/survey/surveyContext'
import ResponseContext from '../../context/response/responseContext'
import Progress from '../layout/Progress'
import Spinner from '../layout/Spinner'
import FormValidator from '../../utils/FormValidator'
import validator from 'validator'
import InfoPopup from '../layout/Popup/InfoPopup'
import useModal from '../layout/Popup/useModal'

const Survey = props => {
  const surveyContext = useContext(SurveyContext)
  const { loading } = surveyContext
  const responseContext = useContext(ResponseContext)
  const { responses } = responseContext
  const { isVisible, toggleModal } = useModal()

  const [ survey, setSurvey ] = useState({
    title: '',
    visible: false,
    published: false,
    created: Date.now(),
    modified: Date.now(),
    questions: []
  })

  const [ disable, setDisable ] = useState(false)

  const message = `This survey already has user responses and cannot be
  edited. This is to maintain the integrity of the results. If you would like to edit, you
  must first delete all current responses for this survey.`

   /* surveyValidator and surveyValidation state are rules for survey validation */
   const surveyValidator = new FormValidator([
    { 
      field: 'title',
      method: validator.isEmpty,
      validWhen: false,
      message: 'Survey must have a title to continue'
    }
  ])
  const [ surveyValidation, setSurveyValidation ] = useState(surveyValidator.valid())


  /* Set survey if coming from props ie editing an existing survey */
  useEffect(() => {
    if(props.location.state) {
      setSurvey({ ...survey, ...props.location.state.survey })
      // Disable edit ability for survey if responses for it exist
      if(responses.filter(response => response.surveyID === props.location.state.survey._id).length > 0) {
        setDisable(true)
        toggleModal()
      }
    }
    // eslint-disable-next-line
  }, [])

  /* handles input events for title and visibility properties */
  const onChange = e => {
    setSurvey({ ...survey, [e.target.name]: e.target.value })
  }

  /***************************************************************************
  * updateQuestion takes param 'index' of question obj and updates its
  * question property or its question_type property. If updating question_type
  * it either clears out the options array, or adds an empty string to it
  ****************************************************************************/
  const updateQuestion = index => e => {
    if(e.target.name === 'question') {
      setSurvey({...survey, questions:[...survey.questions.map(q => 
        q.index === index ? {...q, [e.target.name]: e.target.value } : q )]}) 
    } else {
      let options = []
      if(e.target.value === 'multi') {
        options = ['']
      }
      setSurvey({...survey, questions:[...survey.questions.map(q => 
          q.index === index ? {...q, [e.target.name]: e.target.value, options: options} : q )]}) 
    }
  }

  /************************************************************************ 
  * Remove the question object matching param 'index' from questions array
  * and renumber index values for all question objects with an index value 
  * greater than the param 'index'
  *************************************************************************/
  const removeQuestion = index => e => {
    setSurvey({...survey, questions: [...survey.questions.filter(q => q.index !== index)
                      .map(q => q.index > index ? {...q, index: q.index-1} : q)]})
  }

  /*******************************************************************************
  * updateOptions takes an index param which identifies the question object to be
  * modified, e.target.name indicates the array index for the 'options' property, 
  * and e.target.value is the new value to insert there.
  ********************************************************************************/
  const updateOptions = index => e => {
    setSurvey({...survey, questions:[...survey.questions.map(q => 
      q.index === index ? {...q, options: Object.assign([...q.options], {[e.currentTarget.name]: e.currentTarget.value})} : q )]})
  }

  /*******************************************************************************
  * Delete an option choice from a multiple choice question. Param 'index' indicates
  * the question object to modify, and e.target.name is the array index in the 
  * 'options' property that is to be removed (using Array.slice).
  ********************************************************************************/
  const removeOptions = index => e => {
    setSurvey({...survey, questions:[...survey.questions.map(q => 
      q.index === index ? {...q, options: Object.assign([...q.options.slice(0, parseInt(e.currentTarget.name, 10)), ...q.options.slice(parseInt(e.currentTarget.name, 10)+1)] )} : q )]})
  }

  /* Push /preview to history and pass survey as state */
  const previewSurvey = () => {
    props.history.push({ pathname: '/preview', state: { survey: survey }})
  }

  /* User clicks save button on new survey, call addSurvey from context*/
  const saveSurvey = () => {
    const formVal = surveyValidator.validate(survey)
    setSurveyValidation(formVal)
    if(formVal.isValid) {
      props.history.push({ pathname: '/home/surveys/publish', state: { survey: survey }})
    }
  }

  if (loading)
    return <Spinner />
    
  return (
  <>
    <Progress colors={["bg-mysecondary", "bg-secondary", "bg-secondary", "bg-secondary", "bg-secondary" ]} />
    <div className="d-flex justify-content-end mb-3">

      <button
        onClick={previewSurvey}
        type="button" 
        className="btn btn-primary mx-1">
        Preview <i className="fas fa-images"></i>
      </button>

      <button
        onClick={saveSurvey}
        type="button" 
        className="btn btn-success mx-1">
        Publish <i className="fas fa-share-square"></i>
      </button>
    </div> 

    <form className="bg-light border border-secondary">
      <div className="bg-mysecondary text-white p-3">
        <h1 className="narrow-font">{survey._id ? 'Edit Survey' : 'New Survey'}</h1>
      </div>
      <div className="my-form">
        <div className="form-group">
          <label htmlFor="title">Title</label>
          <input 
            type="text"
            className="form-control"
            name="title" id="title"
            disabled={disable}
            value={survey.title}
            onChange={onChange} 
          />
          <small className="form-text text-muted">Survey name must be unique</small>
          <span className="text-danger">{surveyValidation.title.message}</span>
        </div>

        <label htmlFor="visible">Visibility</label>
        <div className="form-group">
          <select 
            className="form-control col-4" 
            data-style="bg-white border" 
            id="visible" 
            name="visible"
            disabled={disable} 
            value={survey.visible}
            onChange={onChange}
          >
            <option value={false}>Private</option>
            <option value={true}>Public</option>
          </select>
          <small className="form-text text-muted">Determines if other users can view this survey</small>
        </div>
      </div>
    </form>
    <br/>
   
    { survey.questions.map((question) => (
      <Question 
        key={question.index} 
        question={question} 
        onUpdate={updateQuestion} 
        removeQuestion={removeQuestion} 
        handleOptions={updateOptions} 
        removeOptions={removeOptions}
        disable={disable}  
      />
    ))}

    <div className="d-flex justify-content-end">
      <button
        onClick={() => setSurvey({...survey, questions: [...survey.questions, {question: '', question_type: '', index: survey.questions.length, options: [] }] })}
        type="button"
        disabled={disable}
        className="btn btn-success">
        Add Question <i className="fas fa-plus"></i>
      </button>
    </div>
    <InfoPopup isVisible={isVisible} hideModal={toggleModal} message={message} />
  </>
  )
}

export default Survey