import React from 'react'
import { Link } from 'react-router-dom'

const StatusRow = ({ survey, responses }) => {
  const { _id, title, start, end } = survey

  /* */
  const hasNew = () => {
    return responses.some(response => response.read === false)
  }

  return (
    <div key={_id} className="my-item list-group-item statusrow-action text-center border-0 mb-1">
      <Link to={{ pathname: '/home/surveys/manage', state: { survey: survey }}}
        className="text-decoration-none text-warning">{title}
      </Link>
      <p id="start" className="m-0 text-center text-muted">{start ? start.substring(0, 10) : 'n/a'}</p>
      <p id="end" className="m-0 text-center text-muted">{end ? end.substring(0, 10) : 'n/a'}</p>
      <p className="m-0 text-center text-muted">
        <span className="badge badge-primary">{responses.length}</span>
        {hasNew() && <span className="badge badge-danger ml-1">New!</span>}
      </p>
      <Link to={{pathname: `/home/responses/${survey._id}`, state: { survey: survey, responses: responses }}}
        className="btn btn-sm btn-success">Results
      </Link>
    </div>
/*
  <td>{new Date(start).getTime() <= Date.now() && Date.now() <= new Date(end).getTime() ? 'Active' : 'Closed'}</td>
*/
  )
}

export default StatusRow