import React from 'react'
import { Link } from 'react-router-dom'
import Spinner from '../layout/Spinner'

const Preview = props => {
  const survey = props.location.state.survey

  return (
  <>
    <button
      className="btn btn-danger mb-2"
      onClick={() => props.history.push({ pathname: '/home/new_survey', state: { survey: survey }})}>
        <i className="fas fa-arrow-alt-circle-left" /> Previous
    </button>
    <nav className="navbar bg-dark text-mysecondary border-bottom justify-content-around mb-5 p-2">
      <div className="d-flex">
        <i className="fas fa-question-circle fa-3x px-3"></i>
        <span className="navbar-brand">QUIZZLE</span>
      </div>
      <blockquote className='blockquote text-right'>
        <small className='font-italic'>"Customized Survey options for any need!"</small>
        <footer className="blockquote-footer">
          <cite>Crystal from MI</cite>
        </footer>
      </blockquote>
    </nav>

    <div className="container container-main">
      { survey === null ? <Spinner/> : (
        <>
        <div className="bg-pinkish text-white shadow p-4 mb-3">
          <h2>{survey.title}</h2>
        </div>

        <form>
        { survey.questions.map(question =>
          <div key={question.index} className="card shadow rounded mb-3">
            <div className="card-body">
            <span className="float-right text-pinkish survey-numbers">#{question.index+1}</span>
              <h5 className="card-title">{question.question}</h5>

              { question.question_type === 'fill_in' ? (
                <input 
                  type="text"
                  className="form-control"
                  placeholder="Answer..."
                  name={question.index}
                  value={question.answer}
                />
                ) : (
                question.options.map((option, i) => 
                <div key={i} className="custom-control custom-radio">
                  <input
                    type="radio"
                    className="custom-control-input"
                    id={String(question.index)+String(i)}
                    name={question.index}
                    value={option}
                  />
                  <label className="custom-control-label" htmlFor={String(question.index)+String(i)}>{option}</label>
                </div>
              ))}

            </div>
          </div>
        )}
        <button
          type="button"
          className="btn btn-secondary btn-block"
          onClick={()=> alert('This is a non-functional preview, click back when finished')}>
            Submit
        </button>
        </form>
      </>  
      )}
    </div>

    <footer className="d-flex justify-content-around bg-dark mt-5 pt-4 pb-3">
      <div className="align-items-center text-white">
        <p className="m-0 font-weight-bold">Powered by Quizzle</p>
        <small className="text-muted">Copyright @ 2021</small>
      </div>
      <div className='d-flex align-items-center text-white'>
        <span><Link to='/about' className='text-decoration-none'>Visit Us</Link></span>
      </div>
    </footer>
  </>
  )
}

export default Preview