import React from 'react'

/* All component state is raised to parent (Survey) via props below */
const Question = ({ question, onUpdate, removeQuestion, handleOptions, removeOptions, disable }) => {

  return (
    <div className="my-form bg-light mb-2">
      <div className="form-group">
        <label htmlFor="question">Question <span className="badge badge-primary">{question.index+1}</span></label>
        {!disable && <i onClick={removeQuestion(question.index)} className="fas fa-trash-alt trash text-danger mt-1"></i>}
        <input type="text" className="form-control" id={"question" + question.index} name="question" 
        value={question.question} onChange={onUpdate(question.index)} disabled={disable} />
      </div>

      <label htmlFor="question_type">Type</label>
      <div className="form-group">
        <select className="form-control col-lg-4" data-style="bg-white border" id="question_type" name="question_type"
        value={question.question_type} onChange={onUpdate(question.index)} disabled={disable} >
          <option value={''} disabled>Select...</option>
          <option value={'fill_in'}>Fill in</option>
          <option value={'multi'}>Multiple Choice</option>
        </select>
      </div>
      
      <div className="form-group">
        {question.question_type === 'multi' && 
          <label htmlFor="options">Options</label>
        }
        {question.question_type === 'multi' && question.options.map((option, i) => 
          <div className="d-flex mb-2" key={i}>
            <input type="text" className="form-control" placeholder="Enter choice..."
            value={option} name={i} onChange={handleOptions(question.index)} disabled={disable} />

            {i === question.options.length-1 ? 
              <>
              <button disabled={question.options.length === 1 || disable ? true : false} className="btn btn-danger ml-1" onClick={removeOptions(question.index)} name={i}>
                <i className="fas fa-minus"></i>
              </button>
              <button disabled={option === '' || disable ? true : false} className="btn btn-success ml-1" onClick={handleOptions(question.index)} name={question.options.length} value="">
                <i className="fas fa-plus"></i>
              </button>
              </>
            :
              <button disabled={question.options.length === 1 || disable ? true : false} className="btn btn-danger ml-1" onClick={removeOptions(question.index)} name={i}>
                <i className="fas fa-minus"></i>
              </button>
            }
          </div>
        )}
      </div>
    </div>
  )
}

export default Question