import React from 'react'
import ResponseByMonth from './ResponseByMonth'
import PopularityChart from './PopularityChart'

const Graphs = () => {
  return (
    <div className="mygraphs mt-4">
      <div className="col-md bg-white shadow">
        <ResponseByMonth />
      </div>
      <div className="col-md bg-white shadow">
        <PopularityChart />
      </div>
    </div>
  )
}

export default Graphs