import React, { useContext, useState, useEffect } from 'react'
import SurveyContext from '../../context/survey/surveyContext'
import ResponseContext from '../../context/response/responseContext'

const DashboardCards = () => {
  const surveyContext = useContext(SurveyContext)
  const responseContext = useContext(ResponseContext)
  const { surveys } = surveyContext
  const { responses } = responseContext

  const [ state, setState ] = useState({
    begin: Date.now(),
    updated: 0
  })

  /* State and useEffect used to calculate time since last page load */
  useEffect(() => {
    let Timer = setInterval(() => {
      setState({...state, updated: Math.round(((Date.now() - state.begin)/1000)/60) })
    },60000)

    return () => clearInterval(Timer)
  // eslint-disable-next-line
  }, [])

  return (
    <div className="cardrow">
      <div className="dashcard bg-info text-white border-0 shadow">
        <div className="card-body">
          <h4 className="card-title">Total Surveys</h4>
          <h1 className="card-text">{surveys.length}</h1>
          <p className="card-text">
            <small>Updated {state.updated} mins ago</small>
          </p>
        </div>
      </div>

      <div className="dashcard bg-myprimary text-white border-0 shadow">
        <div className="card-body">
          <h4 className="card-title">Total Responses</h4>
          <h1 className="card-text">{responses.length}</h1>
          <p className="card-text">
            <small>Updated {state.updated} mins ago</small>
          </p>
        </div>
      </div>

      <div className="dashcard bg-primary text-white border-0 shadow">
        <div className="card-body">
          <h4 className="card-title">New Responses</h4>
          <h1 className="card-text">{responses.filter(res => res.read === false).length}</h1>
          <p className="card-text">
            <small>Updated {state.updated} mins ago</small>
          </p>
        </div>
      </div>
    </div>
  )
}

export default DashboardCards