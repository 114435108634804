import React, { useContext } from 'react'
import responseContext from '../../context/response/responseContext'
import { Pie } from 'react-chartjs-2'

const PopularityChart = () => {
  const ResponseContext = useContext(responseContext)
  const { responses } = ResponseContext

  /* Add total number of responses in each survey into "combined" object */
  const combined = {}
  responses.forEach(response => {
    combined[response.title] = (combined[response.title] || 0) + 1
  })
  
  /* Loop thru all entries and split them into 2 arrays for the chart 
     one for "labels" and one for "data" sorted by count */
  let labels = []
  let counts = []
  let max = 0
  let iterations = Object.keys(combined).length - 1

  for (let i = 0; i < iterations; i++) {
    max = Object.keys(combined).reduce((a, b) => combined[a] > combined[b] ? a : b)
    labels.push(max)
    counts.push(combined[max])
    delete combined[max]
  }
  let last = Object.keys(combined)
  labels.push(last[0])
  counts.push(combined[last])

  /* Split arrays into top 4 surveys and combine remainder into "other" category */
  let numOfSurveysDisplayed = 5
  labels.splice(numOfSurveysDisplayed -1, labels.length)
  labels.push('All others')
  let other = counts.splice(numOfSurveysDisplayed - 1)
  counts.push(other.reduce((partial_sum, a) => partial_sum + a, 0))

  const data = {
    labels: labels,
    datasets: [
      {
        label: '# of Votes',
        data: counts,
        backgroundColor: [
          '#f96332',
          '#007bff',
          '#32C8F9',
          '#17a2b8',
          '#ffc107'
        ],
        borderColor: [
         '#FFFFFF'
        ],
        borderWidth: 2,
      },
    ],
  }

  const options = {
    legend: {
      display: false
    }
  }
  
  return (
    <>
      <div className='header text-center py-2'>
        <h4 className="m-0">Survey Popularity</h4>
        <p className="text-muted"><small>Number of responses by survey</small></p>
      </div>
    <Pie data={data} options={options} />
  </>
  )
}

export default PopularityChart