import React, { useReducer } from 'react'
import axios from 'axios'
import ResponseContext from './responseContext'
import responseReducer from './responseReducer'
import {
  GET_RESPONSES,
  UPDATE_RESPONSE,
  DELETE_RESPONSE,
  CLEAR_RESPONSES,
  RESPONSE_ERROR,
} from '../types'

const ResponseState = props => {
  const initialState = {
    responses: null,
    error: null
  }

  const [state, dispatch] = useReducer(responseReducer, initialState)

/*
  // Get Responses by surveyID
  const getResponses = async surveyID => {
    try {
      const res = await axios.get(`http://localhost:5000/api/responses?surveyID=${surveyID}`)
      dispatch({ type: GET_RESPONSES, payload: res.data })
    } catch (err) {
        dispatch({ type: RESPONSE_ERROR, payload: err.response })
    }
  }
*/
  // Get Responses
  const getResponses = async () => {
    try {
      const res = await axios.get(`/api/responses`)
      dispatch({ type: GET_RESPONSES, payload: res.data })
    } catch (err) {
        dispatch({ type: RESPONSE_ERROR, payload: err.response })
    }
  }

  // Update Response
  const updateResponse = async response => {
    const config = { headers: { 'Content-type': 'application/json' }}
    try {
      const res = await axios.put(`/api/responses/${response._id}`, response, config)
      dispatch({ type: UPDATE_RESPONSE, payload: res.data })
    } catch (err) {
        dispatch({ type: RESPONSE_ERROR, payload: err.response })
    }
  }

  // Delete Response
  const deleteResponse = async response => {
    const config = { headers: { 'Content-type': 'application/json' }}
    try {
      await axios.delete(`/api/responses/${response._id}`, response, config)
      dispatch({ type: DELETE_RESPONSE, payload: response._id })
    } catch (err) {
        dispatch({ type: RESPONSE_ERROR, payload: err.response })
    }
  }

  // Clear Responses
  const clearResponses = () => {
    dispatch({ type: CLEAR_RESPONSES })
  }

  return (
    <ResponseContext.Provider
      value={{
        responses: state.responses,
        getResponses,
        updateResponse,
        deleteResponse,
        clearResponses
      }}>
        { props.children }
    </ResponseContext.Provider>
  )
}

export default ResponseState