import React, { useState, useContext, useEffect } from 'react'
import AuthContext from '../../context/auth/authContext'
import AlertContext from '../../context/alert/alertContext'
import FormValidator from '../../utils/FormValidator'
import validator from 'validator'
import Navbar from '../layout/Navbar'
import Alerts from '../layout/Alerts'

const Login = props => {
  const authContext = useContext(AuthContext)
  const alertContext = useContext(AlertContext)
  const { isAuthenticated, registerUser, loginUser, error, clearErrors } = authContext
  const { setAlert } = alertContext

  /* Show errors if exist or redirect to home if user is authenticated */
  useEffect(() => {
    if(isAuthenticated) {
      props.history.push('/')
    }
    if(error) {
      setAlert(error, 'danger')
      clearErrors()
    }
    // eslint-disable-next-line
  }, [isAuthenticated, error, props.history])

  const loginState = {
    email: '',
    password: '',
  }
  const [ loginForm, setLoginForm ] = useState(loginState)

  const registerState = {
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    bio: ''
  }
  const [ registerForm, setRegisterForm ] = useState(registerState)

  /* loginValidator and validation state are rules for login validation */
  const loginValidator = new FormValidator([
    { 
      field: 'email',
      method: validator.isEmail,
      validWhen: true,
      message: 'Please enter a valid email address.'
    },
    {
      field: 'password',
      method: validator.isLength,
      args: [{ min:6 }],
      validWhen: true,
      message: 'Password must be at least 6 characters.'
    }
  ])
  const [ loginValidation, setLoginValidation ] = useState(loginValidator.valid())

    /* registerValidator and validation state are rules for register validation */
    const registerValidator = new FormValidator([
      {
        field: 'name',
        method: validator.isEmpty,
        validWhen: false,
        message: 'Please provide your name.'
      },
      { 
        field: 'email',
        method: validator.isEmail,
        validWhen: true,
        message: 'Please enter a valid email address.'
      },
      {
        field: 'password',
        method: validator.isLength,
        args: [{ min:6 }],
        validWhen: true,
        message: 'Password must be at least 6 characters.'
      },
      {
        field: 'confirmPassword',
        method: validator.equals,
        args: [registerForm.password],
        validWhen: true,
        message: 'Passwords must match.'
      },
      {
        field: 'confirmPassword',
        method: validator.isLength,
        args: [{ min:6 }],
        validWhen: true,
        message: 'Password must be at least 6 characters.'
      },
    ])
    const [ registerValidation, setRegisterValidation ] = useState(registerValidator.valid())

  /* Update state values for login input fields */
  const onChange = e => {
    setLoginForm({...loginForm, [e.target.name]: e.target.value })
  }

    /* Update state values for register input fields */
    const onRegisterChange = e => {
      setRegisterForm({...registerForm, [e.target.name]: e.target.value })
    }

  /* Clears all state and fields when user switches tabs */
  const clearFields = () => {
    setLoginForm({...loginState})
    setRegisterForm({...registerState})
  }

  /* Submit login request or register request depending on e.target.name */
  const onSubmit = e => {
    e.preventDefault()
    if(e.target.name === 'loginBtn') {
      const tmpVal = loginValidator.validate(loginForm)
      setLoginValidation(tmpVal)
      if(tmpVal.isValid) {
        loginUser(loginForm)
      }
    } 
    else if(e.target.name === 'registerBtn') {
      const tmpVal = registerValidator.validate(registerForm)
      setRegisterValidation(tmpVal)
      if(tmpVal.isValid) {
        registerUser(registerForm)
      }
    }
  }
  
  return (
    <>
      <Navbar/>
      <div className="container main-content mt-1">
        <Alerts/>
        <div className="row mt-5 justify-content-center">
          <div className="mt-5">
            <ul className="nav bg-myaccent">
              <li className="nav-item ">
                <a href="#login_panel" className="nav-link active text-white px-4 rounded-top advent-pro" data-toggle="tab" role="tab" onClick={clearFields}>Login</a>
              </li>
              <li className="nav-item">
                <a href="#register_panel" className="nav-link text-white pl-4 rounded-top advent-pro" data-toggle="tab" role="tab" onClick={clearFields}>Register</a>
              </li>
            </ul>

            <div className="tab-content">

              {/* Register Panel */}
              <div className="tab-pane fade in show" id="register_panel" role="tabpanel">
                <form action="" className="bg-myprimary p-5">

                  <div className="form-group">
                    <input
                      type="text" 
                      className={"login-input form-control shadow-none border-0" + (registerValidation.name.isInvalid ? " border border-danger" : '')}
                      id="name"
                      name="name"
                      placeholder="Name"
                      aria-describedby="nameHelp"
                      value={registerForm.name}
                      onChange={onRegisterChange}
                    />
                    <span className="text-white">{registerValidation.name.message}</span>
                  </div>

                  <div className="form-group">
                    <input
                      type="email" 
                      className={"login-input form-control shadow-none border-0" + (registerValidation.email.isInvalid ? " border border-danger" : '')}
                      id="register_email"
                      name="email"
                      placeholder="Email"
                      aria-describedby="emailHelp"
                      value={registerForm.email}
                      onChange={onRegisterChange}
                    />
                    <span className="text-white">{registerValidation.email.message}</span>
                  </div>

                  <div className="form-group">
                    <input 
                      type="password" 
                      className={"login-input form-control shadow-none border-0" + (registerValidation.password.isInvalid ? " border border-danger" : '')}
                      id="registerPassword"
                      name="password"
                      placeholder="Password"
                      value={registerForm.password}
                      onChange={onRegisterChange}
                    />
                    <span className="text-white">{registerValidation.password.message}</span>
                  </div>

                  <div className="form-group">
                    <input 
                      type="password" 
                      className={"login-input form-control shadow-none border-0" + (registerValidation.confirmPassword.isInvalid ? " border border-danger" : '')}
                      id="confirmPassword"
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      value={registerForm.confirmPassword}
                      onChange={onRegisterChange}
                    />
                    <span className="text-white">{registerValidation.confirmPassword.message}</span>
                  </div>

                  <div className="form-group">
                    <textarea
                      className="login-input form-control shadow-none border-0"
                      id="bio"
                      name="bio"
                      rows="3"
                      placeholder="Tell us about yourself..."
                      onChange={onRegisterChange}>  
                    </textarea>
                  </div>

                  <div className="form-group py-4">
                    <button onClick={onSubmit} name='registerBtn' className="btn btn-outline-my-secondary btn-block login-bttn">Register</button>
                  </div>
                </form>
              </div>

              {/* Login Panel */}
              <div className="tab-pane fade in show active" id="login_panel" role="tabpanel">
                <form action="" className="bg-myprimary p-5">
                  <div className="form-group py-4">
                    <input 
                      type="email" 
                      className={"login-input form-control shadow-none border-0" + (loginValidation.email.isInvalid ? " border border-danger" : '')}
                      id="email"
                      name="email"
                      placeholder="Email Address"
                      aria-describedby="emailHelp"
                      value={loginForm.email}
                      onChange={onChange}
                    />
                    <span className="text-white">{loginValidation.email.message}</span>
                  </div>

                  <div className="form-group py-2">
                    <input 
                      type="password" 
                      className={"login-input form-control shadow-none border-0" + (loginValidation.password.isInvalid ? " border border-danger" : '')}
                      id="password"
                      name="password"
                      placeholder="Password"
                      value={loginForm.password}
                      onChange={onChange}
                    />
                    <span className="text-white">{loginValidation.password.message}</span>
                  </div>

                  <div className="form-group py-4">
                    <button onClick={onSubmit} name='loginBtn' className="btn btn-outline-my-secondary btn-block login-bttn">Login</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login