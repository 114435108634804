import React, { useEffect, useState } from 'react'
import AnswersList from './AnswersList'
import AnswersGraph from './AnswersGraph'
import Spinner from '../layout/Spinner'

const GroupResponses = ({ survey, responses }) => {
  const [ results, setResults ] = useState(null)
  
  // combine response results for groups response report
  useEffect(() => {
    setResults(calcResults())
  //eslint-disable-next-line
  }, [])

  /* Helper function to combine answers from all responses for render */
  const calcResults = () => {
    let answers = []
    let tmp = []
    for(let i = 0; i < survey.questions.length; i++) {
      for(let j = 0; j < responses.length; j++) {
        tmp.push(responses[j].questions[i].answer)
      }
      answers.push({ index: i, results: tmp })
      tmp = []
    }
    return answers
  }

  if(results === null)
    return <Spinner />
    
  return (
    <div>
      {responses.length === 0 ? (
        <div className="card p-3 mb-2 border-0 shadow">
          <h2 className="narrow-font text-warning">No responses received</h2>
        </div>
      ):(
      survey.questions.map(question =>
        <div key={question._id} className="card mb-2 border-0 shadow">
          <div className="card-header bg-mysecondary survey-numbers text-white text-right">
            #{question.index+1}
          </div>
          <div className="d-flex flex-column p-3">
            <span className="text-warning">Question</span>
            <span>{question.question}</span>
            <span className="text-warning">Type</span>
            <span>{question.question_type === 'multi' ? 'Multiple Choice' : 'Fill-in'}</span>
            {question.question_type === 'multi' && 
              <>
              <span className="text-warning">Options</span>
              {question.options.map((opt, i) => <span key={i}>{'- ' + opt}</span> )}
              </>
            }
          </div>
          <hr className="mx-3 my-0"/>
          <div className='p-3'> 
            <h2 className="narrow-font text-warning">Responses</h2>
            {results.map((responseObj, i) => question.index === responseObj.index &&
              (question.question_type === 'fill_in' ?
                <AnswersList key={i} responseObj={responseObj} />
              :
                <AnswersGraph key={i} responseObj={responseObj} />
              )
            )}
          </div>
        </div>
      ))}
      
    </div>
  )
}

export default GroupResponses