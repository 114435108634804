import React, { useContext, useState } from 'react'
import StatusRow from './StatusRow'
import SurveyContext from '../../context/survey/surveyContext'
import ResponseContext from '../../context/response/responseContext'

const Status = () => {
  const surveyContext = useContext(SurveyContext)
  const responseContext = useContext(ResponseContext)
  const { surveys } = surveyContext
  const { responses } = responseContext
  const [ showAll, setShowAll ] = useState(false)
  const numToShow = 4

  return (
    <div className="mb-1">
      <h4 className="status-header">Active Surveys</h4>
      <div className="header-row border-0 mt-5 mb-1 text-muted mx-3">
        <p className="col-sm-4 m-0 text-left">Title</p>
        <p className="col-sm-2 m-0 text-center">Start</p>
        <p className="col-sm-2 m-0 text-center">End</p>
        <p className="col-sm-2 m-0 text-center">Responses</p>
        <p className="col-sm-2 m-0 text-center">Action</p>  
      </div>
      { surveys.length > 0 ? (
        <div>
          {!showAll ? (
            surveys.filter(survey => survey.published === true).slice(0, numToShow).map(survey => survey.published === true &&
              <StatusRow 
              key={survey._id} 
              survey={survey}
              responses={responses.filter(response => response.surveyID === survey._id)}
            />)
            ) : (
            surveys.map(survey => survey.published === true &&
              <StatusRow 
                key={survey._id} 
                survey={survey}
                responses={responses.filter(response => response.surveyID === survey._id)}
              />)
            )}
          <div className="d-flex justify-content-end">
            {surveys.filter(survey => survey.published === true).length > numToShow ? (
              showAll ? (
                <span
                  className="btn btn-link text-decoration-none"
                  onClick={() => setShowAll(!showAll)}>
                    Show Less <i className="fas fa-angle-double-up"></i>
                </span>
                ) : (  
                  <span
                    className="btn btn-link text-decoration-none"
                    onClick={() => setShowAll(!showAll)}>
                      Show All <i className="fas fa-angle-double-down"></i>
                  </span>
                )
              ) : (
                <p></p>
              )
            }
          </div>
        </div>
        ) : (
          <p className="list-group-item border-0 mb-1">
            No published surveys
          </p>
        )}
    </div>
  )
}

export default Status