import React, { useContext } from 'react'
import StatusRow from '../dashboard/StatusRow'
import SurveyContext from '../../context/survey/surveyContext'
import ResponseContext from '../../context/response/responseContext'

const Surveys = props => {
  const surveyContext = useContext(SurveyContext)
  const responseContext = useContext(ResponseContext)
  const { surveys } = surveyContext
  const { responses } = responseContext

  return (
    <>
      <button onClick={()=>props.history.push('/home/surveys/new_survey')} className="btn btn-lg btn-success mb-5">
        <i className="fas fa-plus text-white"></i> Create Survey
      </button>

      <div className="pt-3 pb-5">
        <h2 className="d-inline-flex narrow-font text-myprimary">Published</h2>
        <hr className="mt-0" />
        <div className="mt-5">
          <div className="list-group mb-1">
            <div className="header-row border-0 mb-1 text-muted mx-3">
              <p className="col-sm-4 m-0 text-left">Title</p>
              <p className="col-sm-2 m-0 text-center">Start</p>
              <p className="col-sm-2 m-0 text-center">End</p>
              <p className="col-sm-2 m-0 text-center">Responses</p>
              <p className="col-sm-2 m-0 text-center">Action</p>  
            </div>
            { surveys ? (
                surveys.map(survey => survey.published === true &&
              /*  surveys.map(survey => */
                <StatusRow 
                  key={survey._id} 
                  survey={survey}
                  responses={responses.filter(response => response.surveyID === survey._id)}
                />)
              ) : (
                <p className="list-group-item border-0 mb-1">
                  No published surveys
                </p>
              )}
          </div>
        </div>
      </div>

      <div className="py-3">
        <h2 className="d-inline-flex narrow-font text-info">Unpublished</h2>
        <hr className="mt-0" />
        <div className="mt-5">
          <div className="list-group mb-1">
            <div className="header-row border-0 mb-1 text-muted mx-3">
              <p className="col-sm-4 m-0 text-left">Title</p>
              <p className="col-sm-2 m-0 text-center">Start</p>
              <p className="col-sm-2 m-0 text-center">End</p>
              <p className="col-sm-2 m-0 text-center">Responses</p>
              <p className="col-sm-2 m-0 text-center">Action</p>  
            </div>
            { surveys ? (
                surveys.map(survey => survey.published === false &&
              /*  surveys.map(survey => */
                <StatusRow 
                  key={survey._id} 
                  survey={survey}
                  responses={responses.filter(response => response.surveyID === survey._id)}
                />)
              ) : (
                <p className="list-group-item border-0 mb-1">
                  No unpublished surveys
                </p>
              )}
          </div>
        </div>
      </div>
    </>
  )
}

export default Surveys
