import React, { useReducer } from 'react'
import axios from 'axios'
import PublicContext from './publicContext'
import publicReducer from './publicReducer'
import {
  GET_PUBLIC_SURVEY,
  SEND_RESPONSE,
  PUBLIC_ERROR,
} from '../types'

const PublicState = props => {
  const initialState = {
    survey: null,
    loading: true,
    error: null,
    success: false
  }

  const [ state, dispatch ] = useReducer(publicReducer, initialState)

  // Get Survey
  const getPublicSurvey = async surveyID => {
    try {
      const res = await axios.get(`/api/public/${surveyID}`)
      dispatch({ type: GET_PUBLIC_SURVEY, payload: res.data })
    } catch (err) {
        console.error(err.response)
        dispatch({ type: PUBLIC_ERROR, payload: err.response.data.msg })
    }
  }

  // Send Response
  const sendResponse = async response => {
    const config = { headers: { 'Content-type': 'application/json' }}
    try {
      const res = await axios.post('/api/public', response, config)
      dispatch({ type: SEND_RESPONSE, payload: res.data })
    } catch (err) {
        console.error(err.response)
        dispatch({ type: PUBLIC_ERROR, payload: err.response.data.msg })
    }
  }

  return (
    <PublicContext.Provider
      value={{
        survey: state.survey,
        loading: state.loading,
        error: state.error,
        success: state.success,
        getPublicSurvey,
        sendResponse
    }}>
      { props.children }
    </PublicContext.Provider>
  )
}

export default PublicState