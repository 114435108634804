import React from 'react'

const Progress = ({ colors }) => {
  return (
    <>
      <div className="row align-items-center px-3">
        <i className={`${colors[0]} fas fa-poll-h fa-2x text-white p-3 rounded-circle`}></i>
        <div className={`${colors[1]} col bg-mysecondary`} style={{height: '5px'}}></div>
        <i className={`${colors[2]} fas fa-paper-plane fa-2x bg-mysecondary text-white p-3 rounded-circle`}></i>
        <div className={`${colors[3]} col bg-mysecondary`} style={{height: '5px'}}></div>
        <i className={`${colors[4]} fas fa-check fa-2x bg-mysecondary text-white p-3 rounded-circle`}></i>
      </div>
      <div className="d-flex justify-content-between mb-4">
        <p className="text-muted">Questions</p>
        <p className="text-muted">Publish</p>
        <p className="text-muted">Complete</p>
      </div>
    </>
  )
}

export default Progress