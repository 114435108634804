import React from 'react'
import { createPortal } from 'react-dom'

const Popup = ({ isVisible, hideModal, removeSurvey }) => {
  return isVisible ? createPortal 
  (
    <div className="popup">
      <div className="popup-content">
        <p>Deleting this survey will also delete any saved user responses. This 
          action cannot be undone. Are you sure you want to proceed?
        </p>
        <button 
          onClick={hideModal}
          className="btn btn-secondary mx-1"
        >
          Cancel
        </button>
        <button 
          onClick={removeSurvey}
          className="btn btn-danger mx-1"
        >
          Delete
        </button>
      </div>
    </div>,
    document.body
  )
  : null
}

export default Popup