import {
  GET_SURVEYS,
  CLEAR_SURVEYS,
  ADD_SURVEY,
  UPDATE_SURVEY,
  DELETE_SURVEY,
  SET_CURRENT,
  CLEAR_CURRENT,
  SURVEY_ERROR,
  SET_LOADING
} from '../types'

export default (state, action) => {
  switch (action.type) {
    case GET_SURVEYS:
      return {
        ...state,
        surveys: action.payload,
        loading: false,
        error: null
      }
    case CLEAR_SURVEYS:
      return {
        ...state,
        surveys: null,
        current: null,
        error: null
      }
    case ADD_SURVEY:
      return {
        ...state,
        surveys: [action.payload, ...state.surveys],
        success: true,
        //current: action.payload,
        loading: false
      }
    case UPDATE_SURVEY:
      return {
        ...state,
        surveys: state.surveys.map(survey => survey._id === action.payload._id ? action.payload : survey),
        success: true,
        //current: action.payload,
        loading: false
      }
    case DELETE_SURVEY:
      return {
        ...state,
        surveys: state.surveys.filter(survey => survey._id !== action.payload),
        loading: false
      }
    case SET_CURRENT:
      return {
        ...state,
        current: action.payload
      }
    case CLEAR_CURRENT:
      return {
        ...state,
        success: null,
        error: null,
        //current: null
      }
    case SET_LOADING:
      return {
        ...state,
        loading: true
      }
    case SURVEY_ERROR:
      return {
        ...state,
        error: action.payload
      }
    default:
      return state
  }
}