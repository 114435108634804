import React from 'react'

const ResponseSuccess = () => {
  return (
    <div className="d-flex flex-column vh-100">
      <nav className="navbar bg-dark text-mysecondary border-bottom justify-content-around mb-5 p-2">
        <div className="d-flex">
          <i className="fas fa-question-circle fa-3x px-3"></i>
          <span className="navbar-brand">QUIZZLE</span>
        </div>
        <blockquote className='blockquote text-right'>
          <small className='font-italic'>"Customized Survey options for any need!"</small>
          <footer className="blockquote-footer">
            <cite>Crystal from MI</cite>
          </footer>
        </blockquote>
      </nav>

      <div className="container text-center mt-5 flex-grow-1">
        <h1 className="success-msg m-4">SUCCESS!</h1>
        <i className="fas fa-check text-success fa-8x mb-5"></i>
        <p className="text-muted">
          Your survey has been submitted and successfully received!
        </p>
        <a href="https://jrg-dev.com/quizzle-splash" className="text-decoration-none">Check Us Out</a>
      </div>

      <footer className="d-flex justify-content-around bg-dark mt-5 pt-4 pb-3">
        <div className="align-items-center text-white">
          <p className="m-0 font-weight-bold">Powered by Quizzle</p>
          <small className="text-muted">Copyright @ 2021</small>
        </div>
        <div className='d-flex align-items-center text-white'>
          <span><a href='https://quizzle.jrg-dev.com/' className='text-decoration-none'>Sign Up</a></span>
        </div>
      </footer>
    </div>
  )
}

export default ResponseSuccess
