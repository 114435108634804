import React, { useContext, useEffect, useState } from 'react'
import AuthContext from '../../context/auth/authContext'
import SurveyContext from '../../context/survey/surveyContext'
import ResponseContext from '../../context/response/responseContext'
import AlertContext from '../../context/alert/alertContext'
import Spinner from '../layout/Spinner'

const Profile = props => {
  const authContext = useContext(AuthContext)
  const surveyContext = useContext(SurveyContext)
  const responseContext = useContext(ResponseContext)
  const alertContext = useContext(AlertContext)
  const { user, updateUser, success, error, clearErrors } = authContext
  const { setAlert } = alertContext

  const [ updatedUser, setUpdatedUser ] = useState(null)
  const [ password, setPassword ] = useState('')
  const [ confirmPassword, setConfirmPassword ] = useState('')

  useEffect(() => {
    if(user)
      setUpdatedUser({...user})

    if(success) {
      setAlert('Update has been saved', 'success')
      clearErrors()
    }
  // eslint-disable-next-line
  },[user, success])

  useEffect(() => {
    if(error) {
      setAlert(error, 'danger')
      clearErrors()
    }
  // eslint-disable-next-line
  },[error])

  /* */
  const onChange = e => {
    setUpdatedUser({...updatedUser, [e.target.name]: e.target.value })
  }

  /* */
  const onChangePassword = e => {
    setPassword(e.target.value)
  }

  /* */
  const onChangeConfirmPassword = e => {
    setConfirmPassword(e.target.value)
  }

  /* */
  const updatePassword = () => {
    if(password.length > 6 && password === confirmPassword) {
      setUpdatedUser({...updatedUser, [password]: password })
    } else {
      setPassword('')
      setConfirmPassword('')
      alert('Passwords must match and contain 6 or more characters')
    }
  }

  /* */
  const onSubmit = e => {
    e.preventDefault()
    updateUser(updatedUser)
  }
  
  if(!updatedUser || surveyContext.surveys === null || responseContext.responses === null)
    return <Spinner />

  return (
    <div>
      <div className="d-flex flex-wrap justify-content-center align-items-center bg-light mb-2 p-2">
        <i className="fas fa-user-circle fa-8x text-myprimary px-3"></i>
        <div className="px-3">
          <span className="text-muted">
            Join Date:
            <span className="h4 narrow-font text-warning px-2">{user.date.substring(0,10)}</span>
          </span>
          <br/>
          <span className="text-muted">
            Total Surveys:
            <span className="h4 narrow-font text-warning px-2">{surveyContext.surveys.length}</span>
          </span>
          <br/>
          <span className="text-muted">
            Total Responses:
            <span className="h4 narrow-font text-warning px-2">{responseContext.responses.length}</span>
          </span>
          <br/>
          <button
            className="btn btn-warning btn-sm text-white mt-2"
            data-toggle="modal"
            data-target="#changePasswordModal">
              <i className="fas fa-lock mr-1"/>Change Password</button>
        </div>
      </div>

      <form className="my-form bg-light">
        <fieldset>
          <div className="form-group">
            <label htmlFor="inputName">Name</label>
            <input
              type="text"
              className="form-control"
              id="inputName"
              aria-describedby="nameHelp"
              name="name"
              value={updatedUser.name}
              onChange={onChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="inputEmail">Email address</label>
            <input
              type="email"
              className="form-control"
              id="inputEmail"
              aria-describedby="emailHelp"
              name="email"
              value={updatedUser.email}
              onChange={onChange}
            />
            <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
          </div>

          <div className="form-group">
            <label htmlFor="inputBio">Bio</label>
            <textarea
              className="form-control"
              id="inputBio"
              rows="3"
              name="bio"
              value={updatedUser.bio}
              onChange={onChange}>
            </textarea>
          </div>
        </fieldset>
        <button
          type="button"
          className="btn btn-danger px-3 mr-2"
          onClick={() => props.history.push('/home/dashboard')}>
            Cancel
        </button>
        <button 
          type="button"
          className="btn btn-secondary px-4"
          onClick={onSubmit}>
            Save
        </button>
      </form>

        {/* Change Password modal */}
      <div className="modal fade" tabIndex="-1" id="changePasswordModal" role="dialog">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Change Password</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              
              <div className="form-group">
                <label htmlFor="inputPassword">New Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="inputPassword"
                  aria-describedby="passwordlHelp"
                  value={password}
                  onChange={onChangePassword}
                />
              </div>
              <div className="form-group">
                <label htmlFor="inputConfirm">Confirm Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="inputConfirm"
                  aria-describedby="passwordlHelp"
                  value={confirmPassword}
                  onChange={onChangeConfirmPassword}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button onClick={()=> {setPassword(''); setConfirmPassword('');}} type="button" className="btn btn-warning" data-dismiss="modal" aria-label="Close">Cancel</button>
              <button onClick={updatePassword} type="button" className="btn btn-secondary" data-dismiss="modal">Ok</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Profile