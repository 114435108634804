import React from 'react'

const Contact = () => {
  return (
    <div className="d-flex flex-column justify-content-center">
      <div className="card text-center p-3 m-3">
        <div className="card-body">
          <h5 className="card-title"><i className="fas fa-phone fa-3x px-3 text-mysecondary"></i></h5>
          <hr/>
          <h6 className="card-subtitle mb-2 text-muted">by phone</h6>
          <p className="card-text">555-555-5555</p>
        </div>
      </div>

      <div className="card text-center p-3 m-3">
        <div className="card-body">
          <h5 className="card-title"><i className="fas fa-envelope fa-3x px-3 text-mysecondary"></i></h5>
          <hr/>
          <h6 className="card-subtitle mb-2 text-muted">by email</h6>
          <p className="card-text">joel.gluch@gmail.com</p>
        </div>
      </div>
  
    </div>
  )
}

export default Contact