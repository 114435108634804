export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAIL = 'REGISTER_FAIL'
export const USER_LOADED = 'USER_LOADED'
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL'
export const AUTH_ERROR = 'AUTH_ERROR'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT = 'LOGOUT'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'

export const GET_SURVEYS = 'GET_SURVEYS'
export const CLEAR_SURVEYS = 'CLEAR_SURVEYS'
export const ADD_SURVEY = 'ADD_SURVEY'
export const UPDATE_SURVEY = 'UPDATE_SURVEY'
export const DELETE_SURVEY = 'DELETE_SURVEY'
export const SET_CURRENT = 'SET_CURRENT'
export const CLEAR_CURRENT = 'CLEAR_CURRENT'
export const SURVEY_ERROR = 'SURVEY_ERROR'

export const GET_PUBLIC_SURVEY = 'GET_PUBLIC_SURVEY'
export const SEND_RESPONSE = 'SEND_RESPONSE'
export const PUBLIC_ERROR = 'PUBLIC_ERROR'

export const GET_RESPONSES = 'GET_RESPONSES'
export const UPDATE_RESPONSE = 'UPDATE_RESPONSE'
export const DELETE_RESPONSE = 'DELETE_RESPONSE'
export const CLEAR_RESPONSES = 'CLEAR_RESPONSES'
export const RESPONSE_ERROR = 'RESPONSE_ERROR'

export const SET_LOADING = 'SET_LOADING'
export const SET_ALERT = 'SET_ALERT'
export const REMOVE_ALERT = 'REMOVE_ALERT'