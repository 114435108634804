import React, { useReducer } from 'react'
import axios from 'axios'
import SurveyContext from './surveyContext'
import surveyReducer from './surveyReducer'
import {
  GET_SURVEYS,
  CLEAR_SURVEYS,
  ADD_SURVEY,
  UPDATE_SURVEY,
  DELETE_SURVEY,
  SET_CURRENT,
  CLEAR_CURRENT,
  SET_LOADING,
  SURVEY_ERROR,
} from '../types'

const SurveyState = props => {  
  const initialState = {
    surveys: null,
    current: null,
    error: null,
    success: null,
    loading: false
  }

  const [state, dispatch] = useReducer(surveyReducer, initialState)

  // Get Surveys
  const getSurveys = async () => {
    try {
      const res = await axios.get('/api/surveys')
      dispatch({ type: GET_SURVEYS, payload: res.data })
    } catch (err) {
        //console.log(err.status)
        dispatch({ type: SURVEY_ERROR, payload: err.response.data.msg })
    }
  }

  // Add Survey
  const addSurvey = async survey => {
    await setLoading()
    const config = { headers: { 'Content-type': 'application/json' }}
    try {
      const res = await axios.post('/api/surveys', survey, config)
      dispatch({ type: ADD_SURVEY, payload: res.data })
    } catch (err) {
        dispatch({ type: SURVEY_ERROR, payload: err.response.data.msg })
    }
  }

  // Update Survey
  const updateSurvey = async survey => {
    await setLoading()
    const config = { headers: { 'Content-type': 'application/json' }}
    try {
      const res = await axios.put(`/api/surveys/${survey._id}`, survey, config)
      dispatch({ type: UPDATE_SURVEY, payload: res.data })
    } catch (err) {
        dispatch({ type: SURVEY_ERROR, payload: err.response.data.msg })
    }
  }

  // Delete Survey
  const deleteSurvey = async id => {
    await setLoading()
    try {
      await axios.delete(`/api/surveys/${id}`)
      dispatch({ type: DELETE_SURVEY, payload: id })
    } catch (err) {
        dispatch({ type: SURVEY_ERROR, payload: err.response.data.msg })
    }
  }

  // Set Loading
  const setLoading = () => {
    dispatch({ type: SET_LOADING })
  }

  // Clear Surveys
  const clearSurveys = () => {
    dispatch({ type: CLEAR_SURVEYS })
  }

  // Set Current Survey
  const setCurrent = survey => {
    dispatch({ type: SET_CURRENT, payload: survey })
  }

  // Clear Current Survey
  const clearCurrent = () => {
    dispatch({ type: CLEAR_CURRENT })
  }

  return (
    <SurveyContext.Provider
      value={{
        surveys: state.surveys,
        current: state.current,
        error: state.error,
        success: state.success,
        loading: state.loading,
        getSurveys,
        clearSurveys,
        addSurvey,
        updateSurvey,
        deleteSurvey,
        setCurrent,
        clearCurrent,
        setLoading
      }}>
        { props.children }
    </SurveyContext.Provider>
  )
}

export default SurveyState