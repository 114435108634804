import React, { useEffect, useContext, useState } from 'react'
import ResponseContext from '../../context/response/responseContext'
import Spinner from '../layout/Spinner'
import GroupResponses from './GroupResponses'
import ListResponses from './ListResponses'

const Responses = props => {
  const survey = props.history.location.state.survey
  const responseContext = useContext(ResponseContext)
  const { responses, updateResponse, deleteResponse } = responseContext
  const [ reportType, setReportType ] = useState('list')
  const [ filteredResponses, setFilteredResponses ] = useState(null)

// calc results and mark all responses as read on server
  useEffect(() => {
    if(responses) {
      setFilteredResponses(responses.filter(responses => responses.surveyID === survey._id))
      responses.filter(responses => responses.surveyID === survey._id).map(response => (response.read === false) && updateResponse({ _id: response._id, read: true }))
    }
  // eslint-disable-next-line
  }, [responses])

  // Delete the selected response
  const removeResponse = response => {
    deleteResponse(response)
  }

  if(filteredResponses === null)
    return <Spinner/>

  return (
    <>
      <div className="bg-light">
        <div className="bg-mysecondary text-white p-3">
          <i className="fas fa-poll fa-3x d-inline"></i>
          <h1 className="narrow-font d-inline ml-2">Survey Results</h1>
        </div>
        <div className="p-3 mb-2">
          <span className="text-muted">
            Title:
            <span className="h4 narrow-font text-warning px-2">{survey.title}</span>
          </span>
          <br/>
          <span className="text-muted">
            Dates:
            <span className="h4 narrow-font text-warning px-2">
              {survey.start ? survey.start.substring(0, 10) : 'n/a'} - {survey.end ? survey.end.substring(0, 10) : 'n/a'}
            </span>
          </span>
          <br/>
          <span className="text-muted">
            Number of Responses:
            <span className="h4 narrow-font text-warning px-2">{filteredResponses.length}</span>
          </span>
          <br/>
          
          <div>
            <div className="dropdown mt-2">
              <button className="btn btn-info dropdown-toggle" type="button" id="reportType" data-toggle="dropdown">
                Response View
              </button>
              <div className="dropdown-menu">
                <button onClick={() => setReportType('list')} className="dropdown-item">Individual Responses</button>
                <button onClick={() => setReportType('group')} className="dropdown-item">Grouped Responses</button>
              </div>
            </div>

            <div className="d-flex justify-content-between">
            <button
              className="btn btn-warning btn-sm text-white mt-2"
              data-toggle="modal"
              data-target="#changePasswordModal">
                <i className="fas fa-file-export mr-1"/>Export Data
            </button>
          {/************** Delete All Code not used currently ************
            <button className="btn btn-danger btn-sm text-white mt-2 px-3">
              <i className="fas fa-trash mr-1"/>Delete All
            </button>
          ****************************************************************/}
            </div>

          </div>
        </div>
      </div>

      { reportType === 'group' ? 
      (
        <GroupResponses responses={filteredResponses} survey={survey} />
      ) : ( 
        <ListResponses responses={filteredResponses} removeResponse={removeResponse} />
      )}
    </>
  )
}

export default Responses
