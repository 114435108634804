import React, { useContext } from 'react'
import SurveyContext from '../../context/survey/surveyContext'
import Popup from '../layout/Popup/Popup'
import useModal from '../layout/Popup/useModal'
import Invite from './Invite'

const Manage = props => {
  const surveyContext = useContext(SurveyContext)
  const { deleteSurvey } = surveyContext
  const { isVisible, toggleModal } = useModal()

  // this is the Published survey object coming from Status or Publish component
  const survey = props.history.location.state.survey
  const { _id, title, published } = survey

  /* Push survey from props to Component survey.js */
  const editSurvey = () => {
    props.history.push({ pathname: '/home/surveys/edit_survey', state: { survey: survey }})
  }

  /* Delete survey from props using _id and navigate to dashboard */
  const removeSurvey = () => {
    deleteSurvey(_id)
    props.history.push({ pathname: '/'})
  }

  return (
    <div>
      <div className="bg-light">
        <div className="bg-mysecondary text-white p-3">
          <div>
            <h1 className="narrow-font m-0"><i className="fas fa-check d-inline"></i> Manage Survey</h1>
          </div>
        </div>
        <div className="px-5 mx-3"><hr/></div>
        <div className="my-form">
          <p className='text-muted p-0 m-0'>Title</p>
          <h1 className='narrow-font text-warning mb-4'>{title}</h1>
          <p className='text-muted p-0 m-0'>Published</p>
          <h1 className="narrow-font text-warning mb-4">{published ? 'Yes' : 'No'}</h1>
          
          {published && <Invite survey={survey} /> }

          <div className="pt-5">
            <button
              onClick={editSurvey}
              type="button" 
              className="btn btn-success mr-1 px-4">
              Edit <i className="fas fa-edit"></i>
            </button>

            <button
             /* onClick={removeSurvey} */
              onClick={toggleModal}
              type="button" 
              className="btn btn-danger ml-1">
              Delete <i className="fas fa-trash-alt"></i>
            </button>
          </div>
        </div>
      </div>
      <Popup isVisible={isVisible} hideModal={toggleModal} removeSurvey={removeSurvey}/>
    </div>
  )
}

export default Manage
