import React, { useEffect, useContext }  from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import PrivateRoute from '../routing/PrivateRoute'
import Navbar from '../layout/Navbar'
import Sidebar from '../layout/Sidebar'
import Alerts from '../layout/Alerts'
//import Breadcrumbs from '../layout/Breadcrumbs'
import Dashboard from '../dashboard/Dashboard'
import Surveys from '../survey/Surveys'
import Survey from '../survey/Survey'
import Preview from '../survey/Preview'
import Responses from '../responses/Responses'
import Publish from '../survey/Publish'
import Complete from '../survey/Complete'
import Manage from '../survey/Manage'
import About from '../pages/About'
import Contact from '../pages/Contact'
import Profile from '../pages/Profile'
import Error404 from '../pages/Error404'
import AuthContext from '../../context/auth/authContext'

const Home = props => {
  const authContext = useContext(AuthContext)

  // if not logged in, send to login page
  useEffect(() => {
    if (authContext.isAuthenticated !== true) {
      props.history.push({ pathname: '/login'})
    }
    //eslint-disable-next-line
  }, [])

  return (
    <>
      <Navbar/>
        <div className="mycontainer">
          <Sidebar/>  
          <div className="container">
          {/*   <Breadcrumbs/>  */}
            <div className="main-content">
              <Alerts/>
              <Switch>
                <Redirect exact from="/" to="/home/dashboard" />
                <PrivateRoute exact path="/home/dashboard" component={Dashboard} />
                <PrivateRoute exact path="/home/surveys" component={Surveys} />
                <PrivateRoute exact path="/home/new_survey" component={Survey} />
                <PrivateRoute exact path="/preview" component={Preview} />
                <PrivateRoute exact path="/home/surveys/new_survey" component={Survey} />
                <PrivateRoute exact path="/home/surveys/edit_survey" component={Survey} />
                <PrivateRoute exact path="/home/responses/:id" component={Responses} />
                <PrivateRoute exact path="/home/surveys/publish" component={Publish} />
                <PrivateRoute exact path="/home/surveys/manage" component={Manage} />
                <PrivateRoute exact path="/home/surveys/complete" component={Complete} />
                <PrivateRoute path="/home/profile" component={Profile} />
                <PrivateRoute exact path="/home/about" component={About}/>
                <PrivateRoute path="/home/contact" component={Contact} />
                <Route component={Error404} />
              </Switch>
            </div>
          </div>
        </div> 
    </>
  )
}

export default Home