import React from 'react'

const ListResponses = ({ responses, removeResponse }) => {

  if(responses.length === 0) {
    return (
    <div className="card p-3 mb-2 border-0 shadow">
      <h2 className="narrow-font text-warning">No responses received</h2>
    </div>
    )
  }
  
  return (
    <div>
      {responses.map(response => (
        <div key={response._id} className="card p-3 mb-2 border-0 shadow">
          <div className="d-flex justify-content-between">
            <small className='text-muted'>Date Survey Taken: {response.date.substring(0, 10)}</small>
            <i onClick={() => removeResponse(response)} className="fas fa-times-circle text-danger trash-response"></i>
          </div>
          <hr/>
          {response.questions.map(q =>
            <div key={q._id}>
              <h3 className="text-warning">#{q.index + 1}</h3>
              <p className="m-0">Q: {q.question}</p>
              <p>A: {q.answer}</p>
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

export default ListResponses