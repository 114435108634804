import {
  GET_PUBLIC_SURVEY,
  SEND_RESPONSE,
  PUBLIC_ERROR,
} from '../types'

export default (state, action) => {
  switch (action.type) {
    case GET_PUBLIC_SURVEY:
      return {
        ...state,
        survey: action.payload,
        error: null,
        loading: false
      }
    case SEND_RESPONSE:
      return {
        ...state,
        survey: null,
        error: null,
        loading: false,
        success: true
      }
    case PUBLIC_ERROR:
      return {
        ...state,
        error: action.payload
      }
    default:
      return {
        state 
      }
  }
}