import React from 'react'
import { Link } from 'react-router-dom'

const Error404 = () => {
  return (
    <div>
      <div className="text-center">
        <h1 className="error-title">4<i className="far fa-grimace text-myprimary face-size"></i>4</h1>
      </div>
      <div className="d-flex justify-content-center">
        <p className="text-secondary text-center p-3 w-75">
          <span className="error-emphasis">OH NO! </span>
          We can't seem to locate the page you're looking for.
          Possible reasons for this can be an incorrect URL, the page may 
          have moved or been renamed, or the web server could be experiencing
          issues right now.
        </p>
      </div>
      <div className="text-center mt-5">
        <Link to="/" className="btn btn-outline-info">Return to Home</Link>
      </div>
    </div>
  )
}

export default Error404