import React, { useContext } from 'react'
import responseContext from '../../context/response/responseContext'
import { Bar } from 'react-chartjs-2'

const ResponseByMonth = () => {
  const ResponseContext = useContext(responseContext)
  const { responses } = ResponseContext

  /* Calculate number of responses per month for previous 12 months */
    let monthCount = {'Jan': 0, 'Feb': 0, 'Mar': 0, 'Apr': 0, 'May': 0, 'Jun': 0, 'Jul': 0, 'Aug': 0, 'Sep': 0, 'Oct': 0, 'Nov': 0, 'Dec': 0}
    let monthArr = []
    let countArr = []
    let current = new Date()

    // Sort monthArr in order by previous 12 months, these are labels for the x-axis
    monthArr.push(current.toLocaleString('default', { month: 'short' }))
    for(let i = 0; i < 11; i++) {
      current.setMonth(current.getMonth()-1)
      let previous = current.toLocaleString('default', { month: 'short' })
      monthArr.push(previous)
    }
    monthArr.reverse() // reverse order so labels move right to left

    // count occurrences by month into monthCount object
    responses.forEach((response) => {
      monthCount[new Date(response.date).toLocaleString('default', { month: 'short' })] += 1
    })

    // fill countArr with counts from monthCount object, these are the data for the graph
    monthArr.forEach(month => {
      countArr.push(monthCount[month])
    })

    // data object formated for chartjs
    const numMonths = 6 //number of months to display on graph
    const data = {
      labels: monthArr.slice(numMonths),
      datasets: [
        {
          label: 'Number of Responses',
          data: countArr.slice(numMonths),
          backgroundColor: '#f96332'
        },
      ],
    }
    // options object for chartjs
    const options = {
      scales: {
        yAxes: [
          {
            ticks: {
              //beginAtZero: true,
              maxTicksLimit: 6,
              padding: 8
            },
            gridLines: {
              lineWidth: 3,
              drawOnChartArea: false,
              drawTicks: false,
            }
          },
        ],
        xAxes: [
          {
            ticks: {
              padding: 8
            },
            gridLines: {
              lineWidth: 3,
              drawOnChartArea: false,
              drawTicks: false,
            }
          },
        ]
      },
      legend: {
        display: false
      }
    }

  return (
    <>
      <div className='header text-center py-2'>
        <h4 className="m-0">Responses by Month</h4>
        <p className="text-muted"><small>Combined total for all surveys</small></p>
      </div>    
      <Bar data={data} options={options} />
    </>
  )
}

export default ResponseByMonth