import React, { useState } from 'react'

const Invite = ({ survey }) => {
  const [ inviteVisible, setInviteVisible ] = useState(false)
  const [ copied, setCopied ] = useState(false)
  const URL = (survey._id !== undefined) ? `https://quizzle.jrg-dev.com/public/${survey._id}` : 'Not yet available'

  const invite = `Greetings!
  
You have been invited to fill out a Quizzle survey. Quizzle is an \
all-in-one survey and response gathering software. Please visit the link below when ready \
to complete your survey.

Survey Name: ${survey.title}
Number of Questions: ${survey.questions.length}
URL: ${URL}

Thank you in advance for completing the survey! And remember to visit Quizzle.com for all your response gathering needs.

The Quizzle Team`

  // copy to clipboard and set copied to true if successful
  async function copyToClipboard() {
    await navigator.clipboard.writeText(invite)
      .then(setCopied(true))
      .catch(err => console.error(err))
  }

  return (
    <>
      <div>
        <p className='text-muted p-0 m-0'>Dates</p>
        <h1 className="narrow-font text-warning mb-4">
          {survey.start ? survey.start.substring(0, 10) : 'n/a'} - {survey.end ? survey.end.substring(0, 10) : 'n/a'}  
        </h1>       
      </div>

      <p className='text-muted mb-1'>Invite:
        <a className="text-decoration-none" data-toggle="collapse" href="#invite" onClick={() => setInviteVisible(!inviteVisible)}>
          {inviteVisible ? ' Click to hide▲' : ' Click to show▼'}
        </a>
      </p>
      
      <div className="collapse" id="invite">
        <textarea className="form-control bg-white text-muted" rows="14"  value={invite} disabled></textarea>
      </div>

      <button 
        className={"btn " + (copied ? "btn-success" : "btn-warning") + " text-white my-2"}
        onClick={copyToClipboard}  
      >
        {copied ? 'Copied!' : 'Copy to Clipboard'}
      </button>
      
      <div className="mt-3">
        <span className="text-muted">Survey URL: </span>
          <a
            className="text-decoration-none d-block text-truncate"
            target="_blank"
            rel="noopener noreferrer"
            href={URL}
          >
            {URL}
          </a>
      </div>
    </>
  )
}

export default Invite