import React, {useContext} from 'react'
import { Link } from 'react-router-dom'
import AuthContext from '../../context/auth/authContext'
import SurveyContext from '../../context/survey/surveyContext'

const Sidebar = () => {
  const authContext = useContext(AuthContext)
  const surveyContext = useContext(SurveyContext)
  const { logout } = authContext
  const { clearSurveys } = surveyContext

  /* */
  const onLogout = () => {
    logout()
    clearSurveys()
  }

  return (
    <>
      <button id="menuBttn" className="navbar-toggler text-left" type="button" data-toggle="collapse" data-target="#sidebar">Menu
        <span className="text-secondary float-right"><i className="fas fa-bars" aria-hidden="true"></i></span>
      </button>

      <div className="list-group-item-light collapse" id="sidebar">
        <div className="list-group w-100">
          <Link to={'/home/dashboard'} className="list-group-item list-group-item-action list-group-item-light border-0 small"><i className="fas fa-home"/> Dashboard</Link>
          <Link to={'/home/new_survey'} className="list-group-item list-group-item-action list-group-item-light border-0 small"><i className="fas fa-edit"/> Create Survey</Link>
          <Link to={'/home/surveys'} className="list-group-item list-group-item-action list-group-item-light border-0 small"><i className="fas fa-poll-h"/> Surveys</Link>
          <Link to={'/home/profile'} className="list-group-item list-group-item-action list-group-item-light border-0 small"><i className="fas fa-user"/> Profile</Link>
          <a className="list-group-item list-group-item-action list-group-item-light border-0 small" onClick={onLogout} href="#!"><i className="fas fa-sign-out-alt"/> Logout</a>
        </div>
      </div>
    </>
  )
}

export default Sidebar