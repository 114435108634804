import React, { useEffect, useState, useContext } from 'react'
import PublicContext from '../../context/public/publicContext'
import AlertContext from '../../context/alert/alertContext'
import Alerts from '../layout/Alerts'
import Spinner from '../layout/Spinner'

const PublishedSurvey = props => {
  const publicContext = useContext(PublicContext)
  const alertContext = useContext(AlertContext)
  const { getPublicSurvey, survey, error, success, sendResponse } = publicContext
  const { setAlert } = alertContext

  const [ response, setResponse ] = useState(null)

  useEffect(() => { 
    const { id } = props.match.params

    if(survey === null) {
      getPublicSurvey(id)
    }
    else if(survey && !error && !success) {
      setResponse(survey)
    }
    else if(error !== null) {
      setAlert(error, 'danger')
    }
    else {
      props.history.push('/public/success')
    }
  // eslint-disable-next-line
  }, [survey, error])

  /*  */
  const onChange = e => {
    setResponse({...response, questions: [...response.questions.map(q => q.index === parseInt(e.target.name, 10) ? {...q, answer: e.target.value } : q )]})
  }

  /* */
  const onSubmit = e => {
    e.preventDefault()
    sendResponse(response)
  }

  return (
  <>
    <nav className="navbar bg-dark text-mysecondary border-bottom justify-content-around mb-5 p-2">
      <div className="d-flex">
        <i className="fas fa-question-circle fa-3x px-3"></i>
        <span className="navbar-brand">QUIZZLE</span>
      </div>
      <blockquote className='blockquote text-right'>
        <small className='font-italic'>"Customized Survey options for any need!"</small>
        <footer className="blockquote-footer">
          <cite>Crystal from MI</cite>
        </footer>
      </blockquote>
    </nav>

    <div className="container main-content flex-grow-1">
      <Alerts/>
      { response === null ? <Spinner/> : (
        <>
        <div className="bg-pinkish text-white shadow p-4 mb-3">
          <h2>{response.title}</h2>
        </div>

        <form onSubmit={onSubmit}>
        { response.questions.map(question =>
          <div key={question.index} className="card shadow rounded mb-3">
            <div className="card-body">
            <span className="float-right text-pinkish survey-numbers">#{question.index+1}</span>
              <h5 className="card-title">{question.question}</h5>

              { question.question_type === 'fill_in' ? (
                <input 
                  type="text"
                  className="form-control"
                  placeholder="Answer..."
                  name={question.index}
                  value={question.answer}
                  onChange={onChange}
                />
                ) : (
                question.options.map((option, i) => 
                <div key={i} className="custom-control custom-radio">
                  <input
                    type="radio"
                    className="custom-control-input"
                    id={String(question.index)+String(i)}
                    name={question.index}
                    value={option}
                    checked={question.answer === option}
                    onChange={onChange}
                  />
                  <label className="custom-control-label" htmlFor={String(question.index)+String(i)}>{option}</label>
                </div>
              ))}

            </div>
          </div>
        )}
        <button type="submit" className="btn btn-secondary btn-block">Submit</button>
        </form>
      </>  
      )}
    </div>

    <footer className="d-flex justify-content-around bg-dark mt-5 pt-4 pb-3">
      <div className="align-items-center text-white">
        <p className="m-0 font-weight-bold">Powered by Quizzle</p>
        <small className="text-muted">Copyright @ 2021</small>
      </div>
      <div className='d-flex align-items-center text-white'>
        <span><a href='https://jrg-dev.com' className='text-decoration-none'>Visit Us</a></span>
      </div>
    </footer>
  </>
  )
}

export default PublishedSurvey