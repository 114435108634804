import React, { useEffect, useState } from 'react'
import Spinner from '../layout/Spinner'
import { HorizontalBar } from 'react-chartjs-2'

const AnswersGraph = ({ responseObj }) => {

  useEffect(() => {
    let tmpLabels = []
    let tmpData = []
    var counts = {}
    responseObj.results.forEach(function(x) { counts[x] = (counts[x] || 0)+1; });
    for (const answer in counts) {
      tmpLabels.push(answer)
      tmpData.push(counts[answer])
    }
    
    setData({...data,
      labels: tmpLabels, 
      datasets: [
        {
          data: tmpData,
          fill: true,
          backgroundColor: ["rgba(255, 99, 132, 0.2)","rgba(75, 192, 192, 0.2)","rgba(54, 162, 235, 0.2)","rgba(153, 102, 255, 0.2)"],
          borderColor:["rgb(255, 99, 132)","rgb(75, 192, 192)","rgb(54, 162, 235)","rgb(153, 102, 255)"],
          borderWidth: 1,
        }
      ], 
      options: {
        "title": { "display": true, "text" : "Results Graph"},
        "legend": { "display": false },
        "scales": {
          "xAxes": [ 
            { "ticks": { "beginAtZero": true, "stepSize": 1 }},
          ]
        }
      }
    })
  // eslint-disable-next-line
  }, [])

  const [ data, setData ] = useState(null)

  if(data === null)
    return <Spinner />
  return (
    <div>
      <HorizontalBar
        data={data}
        options={data.options}
      />
    </div>
  )
}

export default AnswersGraph