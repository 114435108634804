import React from 'react'

const AnswersList = ({ responseObj }) => {
  return (
    <div>
      {responseObj.results.map((answer, i) => 
        <span className="d-block" key={i}>Survey {i+1 + '. ' + answer}</span>
      )}
    </div>
  )
}

export default AnswersList