import React, { useEffect, useContext } from 'react'
import Progress from '../layout/Progress'
import SurveyContext from '../../context/survey/surveyContext'
import Invite from './Invite'
import Spinner from '../layout/Spinner'

const Complete = props => {
  // this is the Published survey object coming from Status component
  const survey = props.history.location.state.survey
  const surveyContext = useContext(SurveyContext)
  const { title, published } = survey
  
/* Clear success state from state */
  useEffect(() => {
    surveyContext.clearCurrent()
    // eslint-disable-next-line
  }, [])

  if(surveyContext.loading || survey === null)
    return <Spinner />

  return (
    <div>
      <Progress colors={["bg-mysecondary", "bg-mysecondary", "bg-mysecondary", "bg-mysecondary", "bg-mysecondary" ]} />
      <div className="bg-light">
        <div className="bg-mysecondary text-white p-3">
          <div>
            <h1 className="narrow-font m-0"><i className="fas fa-check d-inline"></i> Complete</h1>
          </div>
        </div>
        <p className="my-form text-info text-center">
          Your survey has been completed!<br/>You can modify the survey questions or it's 
          publish details at any time by visiting the Surveys page.
        </p>
        <div className="px-5 mx-3"><hr/></div>
        <div className="my-form">
          <p className='text-muted p-0 m-0'>Title</p>
          <h1 className='narrow-font text-warning mb-4'>{title}</h1>
          <p className='text-muted p-0 m-0'>Published</p>
          <h1 className="narrow-font text-warning mb-4">{published ? 'Yes' : 'No'}</h1>
          
          {published && <Invite survey={survey} /> }
          
        </div>
      </div>
    </div>
  )
}

export default Complete