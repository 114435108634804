import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import PrivateRoute from './components/routing/PrivateRoute'
import { ToastContainer } from 'react-toastify';
import Login from './components/auth/Login'
import Home from './components/pages/Home'
import PublishedSurvey from './components/public/PublishedSurvey'
import ResponseSuccess from './components/public/ResponseSuccess'
import AuthState from './context/auth/AuthState'
import SurveyState from './context/survey/SurveyState'
import ResponseState from './context/response/ResponseState'
import PublicState from './context/public/PublicState'
import AlertState from './context/alert/AlertState'
import './App.css';

const App = () => {
  return (
    <AuthState>
      <PublicState>
        <SurveyState>
          <ResponseState>
            <AlertState>
              <Router>
                <ToastContainer />
                  <Switch>
                    <Route exact path='/login' component={Login} />
                    <Route exact path='/public/success' component={ResponseSuccess} />
                    <Route exact path='/public/:id' component={PublishedSurvey} />
                    <PrivateRoute path='/' component={Home} />
                  </Switch>
              </Router>
            </AlertState>
          </ResponseState>
        </SurveyState>
      </PublicState>
    </AuthState>
  );
}

export default App;